<template>
  <b-table
    v-if="tableData.length > 0"
    striped
    hover
    responsive
    :fields="fields"
    :items="tableData"
  >
    <template #cell(send_date)="data">
      {{ data.item.send_date? moment(data.item.send_date).format('DD.MM.YYYY') : '-' }}
    </template>
    <template #cell(arrival_date)="data">
      {{ data.item.arrival_date? moment(data.item.arrival_date).format('DD.MM.YYYY') : '-' }}
    </template>
    <template #cell(control)="data">
      <b-dropdown
        v-if="allowedUpdate.includes(userData.id_com_user_type)"
        :split-to="'/damage-tracking/repair/edit/' + data.item.id"
        split
        block
        size="sm"
        text="Güncelle"
        variant="outline-primary"
      >
        <b-dropdown-item
          v-if="userData.id_com_user_type === '1'"
          @click="deleteData(data.item.id)"
        >
          Sil
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
  <b-alert
    v-else
    show
    class="ml-2 mr-2"
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      Parça Tamir Takibi verisi bulunmamaktadır.
    </div>
  </b-alert>
</template>
<script>

import {
  BAlert,
  BDropdown, BDropdownItem, BTable,
} from 'bootstrap-vue'

export default {
  name: 'RepairListTable',
  components: {
    BAlert,
    BDropdownItem,
    BDropdown,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      require: true,
    },
    deleteData: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '17', '18', '19'],
      fields: [
        {
          key: 'send_date',
          label: 'Gönderim Tarihi',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'arrival_date',
          label: 'Geliş Tarihi',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'component_name',
          label: 'Parça',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'damage_repair_status',
          label: 'Durum',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'username',
          label: 'Danışman',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
          tdClass: 'width-100 text-nowrap font-small-3',

        },
      ],
    }
  },
}
</script>
