<template>
  <b-table
    v-if="tableData.length > 0"
    striped
    hover
    responsive
    :fields="fields"
    :items="tableData"
  >
    <template #cell(pdate)="data">
      {{ data.item.pdate? moment(data.item.pdate).format('DD.MM.YYYY') : '-' }}

    </template>
    <template #cell(notes)="data">
      {{ data.item.notes? data.item.notes : '-' }}
      <template v-if="data.item.documents.length > 0">
        <div v-if="data.item.documents.length > 1">
          <small class="text-primary">
            <feather-icon icon="FileTextIcon" />
            <b-link
              :to="'/damage-tracking/lines/edit/' + data.item.id"
            >  {{ data.item.documents.length }} Ek Doküman
            </b-link>

          </small>
        </div>
        <div v-if="data.item.documents.length < 2">
          <small class="text-primary">
            <feather-icon icon="FileTextIcon" />
            <a
              :href="baseURL + data.item.documents[0].filename"
              target="_blank"
            > 1 Ek Doküman
            </a>
          </small>
        </div>
      </template>

    </template>
    <template #cell(control)="data">
      <b-dropdown
        v-if="allowedUpdate.includes(userData.id_com_user_type)"
        :split-to="'/damage-tracking/lines/edit/' + data.item.id"
        split
        block
        size="sm"
        text="Güncelle"
        variant="outline-primary"
      >
        <b-dropdown-item
          v-if="userData.id_com_user_type === '1'"
          @click="deleteData(data.item.id)"
        >
          Sil
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
  <b-alert
    v-else
    show
    class="ml-2 mr-2"
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      Takip durum verisi bulunmamaktadır.
    </div>
  </b-alert>
</template>
<script>

import {
  BAlert, BLink,
  BDropdown, BDropdownItem, BTable,
} from 'bootstrap-vue'

export default {
  name: 'TrackingLineTable',
  components: {
    BLink,
    BAlert,
    BDropdownItem,
    BDropdown,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      require: true,
    },
    deleteData: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/damage_tracking_documents/`,
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '17', '18', '19'],
      fields: [
        {
          key: 'pdate',
          label: 'Tarih',
        },
        {
          key: 'notes',
          label: 'Durum Notu',
        },
        {
          key: 'damage_tracking_status',
          label: 'Durum',
        },
        {
          key: 'username',
          label: 'Danışman',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
}
</script>
