<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>Genel</span>
        </template>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Takip Kartı Bilgileri
            </h4>
            <div
              v-if="dataItem.tracking_no"
              class="text-right"
            >
              <h3 class="text-primary">
                #{{ dataItem.tracking_no }}
              </h3>
              <div v-if="dataItem.document_no">
                <h5 class="text-muted">
                  #{{ dataItem.document_no }}
                </h5>
              </div>
            </div>
          </template>
          <b-row>
            <b-col>
              <b-list-group>
                <list-item
                  title="Müşteri"
                  :value="dataItem.customer ? dataItem.customer : dataItem.company ? dataItem.company : '-'"
                />
                <list-item
                  title="Giriş Tarihi"
                  :value="moment(dataItem.entry_date).format('DD.MM.YYYY') + ' (' +moment().diff(moment(dataItem.entry_date),'days') + ' gün önce)'"
                />
                <list-item
                  title="Tahmini Çıkış Tarihi"
                  :value="dataItem.release_date ? moment(dataItem.release_date).format('DD.MM.YYYY') : '-'"
                />
                <list-item
                  title="Onarım Başlangıç Tarihi"
                  :value="dataItem.repair_date ? moment(dataItem.repair_date).format('DD.MM.YYYY') : '-'"
                />
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <b-list-group-item v-if="dataItem.id_com_damage_tracking_type || dataItem.id_com_location">
                  <div class="font-weight-bold text-primary">
                    Takip Tipi
                  </div>
                  {{ dataItem.damage_tracking_type }}
                  <div v-if="dataItem.id_com_location">
                    <small class="text-info">{{ dataItem.location }}</small>
                  </div>
                </b-list-group-item>
                <list-item
                  title="Ödeme Tipi"
                  :value="dataItem.payment_type === '0' ? 'Sigorta Ödemeli' : 'Müşteri Ödemeli'"
                />
                <list-item
                  title="Ekspertiz Ücreti"
                  :value="dataItem.expertise_price ? dataItem.expertise_price + '₺' : 'Ücret alınmadı.'"
                />
                <list-item
                  title="Ekspertiz Tarihi"
                  :value="dataItem.expertise_date ? moment(dataItem.expertise_date).format('DD.MM.YYYY') : '-'"
                />

              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <list-item
                  title="Sigorta Firması"
                  :value="dataItem.damage_tracking_insurance ? dataItem.damage_tracking_insurance : dataItem.payment_type ? 'Müşteri Ödemeli' : '-'"
                />
                <list-item
                  title="Araç Plakası"
                  :value="dataItem.vip ? dataItem.license_plate + ' - Vip Araç' : dataItem.license_plate"
                  :class="dataItem.vip ? 'text-warning' : null"
                />
                <list-item
                  title="Eksper Adı"
                  :value="dataItem.expertise_name ? dataItem.expertise_name : '-'"
                />
                <list-item
                  title="Danışman"
                  :value="dataItem.username"
                />
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
        <kvkk-inline
          v-if="getData && customerData.kvkk != '1'"
          :customer-id="dataItem.id_com_customer"
          :refresh-data="getData"
        />
        <b-card no-body>
          <b-card-header>
            <b-card-title>İşlemler</b-card-title>
            <div class="text-right">
              <b-button
                variant="primary"
                class="text-right"
                :to="'/damage-tracking/lines/add/' + this.$route.params.id"
              >
                <FeatherIcon icon="PlusIcon" />
                Durum Ekle

              </b-button>
            </div>
          </b-card-header>
          <tracking-line-table
            :table-data="trackingLines"
            :delete-data="deleteLine"
          />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Parça Tamir Takibi</span>
        </template>

        <b-card no-body>
          <b-card-header>
            <b-card-title>Parça Durumları</b-card-title>

            <div class="text-right">
              <b-button
                variant="primary"
                class="text-right"
                :to="'/damage-tracking/repair/add/' + this.$route.params.id"
              >
                <FeatherIcon icon="PlusIcon" />
                Kayıt Ekle

              </b-button>
            </div>
          </b-card-header>
          <repair-list-table
            :table-data="trackingRepairs"
            :delete-data="repairListDelete"
          />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Dokümanlar</span>
        </template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Dokümanlar</b-card-title>

            <div class="text-right">
              <b-button
                variant="primary"
                class="text-right"
                :to="'/damage-tracking/document/add/' + this.$route.params.id"
              >
                <FeatherIcon icon="PlusIcon" />
                Doküman Ekle
              </b-button>
            </div>
          </b-card-header>
          <document-list-table
            :table-data="trackingDocuments"
            :delete-data="deleteDocument"
          />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-view />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import {
  BTab, BTabs, BCard, BButton, BCardHeader, BCardTitle, BListGroup, BRow, BCol, BListGroupItem,
} from 'bootstrap-vue'
import CustomerView from '@/views/Customers/CustomerView.vue'
import TrackingLineTable from '@/views/Damage_tracking/components/TrackingLineTable.vue'
import RepairListTable from '@/views/Damage_tracking/Repair/RepairListTable.vue'
import DocumentListTable from '@/views/Damage_tracking/document/DocumentListTable.vue'
import ListItem from '@/views/Customers/view/ListItem.vue'
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'

export default {
  name: 'DamageTrackingView',
  components: {
    BListGroupItem,
    KvkkInline,
    BListGroup,
    ListItem,
    DocumentListTable,
    RepairListTable,
    BButton,
    TrackingLineTable,
    BTabs,
    BTab,
    BCard,
    CustomerView,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageTracking/getData']
    },
    trackingLines() {
      return this.$store.getters['damageTrackingLine/getDataList']
    },
    trackingRepairs() {
      return this.$store.getters['damageRepair/getDataList']
    },
    trackingDocuments() {
      return this.$store.getters['damageTrackingDocument/getDataList']
    },
    customerData() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    this.getData()
    this.getLines()
    this.getRepairList()
    this.getDocumentList()
  },
  methods: {
    getData() {
      this.$store.dispatch('damageTracking/dataItem', this.$route.params.id)
        .then(res => {
          if (res.id_com_customer) {
            this.$store.dispatch('customers/customerView', res.id_com_customer)
          }
        })
    },
    getLines() {
      return this.$store.dispatch('damageTrackingLine/dataList', {
        where: {
          'com_damage_tracking_line.id_com_damage_tracking': this.$route.params.id,
        },
      })
    },
    getRepairList() {
      return this.$store.dispatch('damageRepair/dataList', {
        where: {
          'com_damage_car_repair.id_com_damage_tracking': this.$route.params.id,
        },
      })
    },
    getDocumentList() {
      return this.$store.dispatch('damageTrackingDocument/dataList', {
        where: {
          'com_damage_tracking_documents.id_com_damage_tracking': this.$route.params.id,
        },
      })
    },
    deleteLine(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('damageTrackingLine/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getLines()
              }
            })
        }
      })
    },
    repairListDelete(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('damageRepair/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getRepairList()
              }
            })
        }
      })
    },
    deleteDocument(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('damageTrackingDocument/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$store.dispatch('damageTrackingDocument/dataList', {
                  where: {
                    'com_damage_tracking_documents.id_com_damage_tracking': this.$route.params.id,
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
